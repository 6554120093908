
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  Insurance,
  PaginationVM,
  RmtInsuranceAgreement,
  UpdateInsuranceStatus,
  SelectVM,
  InsuranceAgreementsVM,
  DoctorSelectVM,
  Role
} from '@/models/index'
import Multiselect from 'vue-multiselect'
import {
  getInsuranceAgreements,
  postInsuranceAgreement,
  updateInsuranceStatus,
  getInsuranceAgreementsResolve,
  updateInsuranceAgreement
} from '@/components/insurances/insurance.service'
import moment from 'moment'
import { getCurrentUser, getUserRole } from '../admin/users/users.service'
import { getResolve } from '../admin/forms.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

@Component({
  components: {
    Multiselect
  }
})
export default class InsuranceAgreements extends Vue {
  public fields = [
    {
      key: 'index'
    },
    {
      key: 'insuranceName',
      label: 'Insurance Name',
      sortable: true
    },
    {
      key: 'ipaNumber',
      label: 'IPA Number',
      sortable: true
    },
    {
      key: 'doctorName',
      label: 'Doctor Name',
      sortable: true
    },
    {
      key: 'doctorDocumentID',
      label: 'NPI',
      sortable: true
    },
    {
      key: 'valueOfPremium',
      label: 'Value of Premium',
      sortable: true
    },
    {
      key: 'expenses',
      label: 'Expenses',
      sortable: true
    },
    {
      key: 'surplusAgreement',
      label: 'Surplus Agreement',
      formatter: 'getPercentage',
      sortable: true
    },
    {
      key: 'agreementDate',
      label: 'Agreement Date',
      formatter: 'formatDate',
      sortable: true
    },
    {
      key: 'show_details',
      label: 'Actions'
    }
  ]
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  public items: RmtInsuranceAgreement[] = []
  public form = {} as InsuranceAgreementsVM
  public isEditing = false
  public userRole = ''
  public selectedInsurance: SelectVM | null = null
  public selectedDoctor: SelectVM | null = null
  public insuranceList: SelectVM[] = []
  public doctorList: DoctorSelectVM[] = []

  public searchQuery = ''
  public currentUser = ''
  public showSearch = false
  public searchLabel = 'Search by Insurance Name or Doctor Name:'
  private async created() {
    this.userRole = getUserRole()
    if (this.userRole == Role.Doctor || this.userRole == Role.Staff) {
      this.currentUser = getCurrentUser()
      // this.editIPA = true
      this.fields = this.fields.filter(
        c => c.key != 'doctorName' && c.key != 'doctorDocumentID'
      )
      this.showSearch = false
    } else {
      this.showSearch = true
      this.searchLabel = 'Search by Insurance Name, Doctor Name or NPI:'
    }
    await this._getInsurances()
  }
  public async filter() {
    await this._getInsurances(1, this.searchQuery)
  }
  public async cleanFilter() {
    this.searchQuery = ''
    await this._getInsurances(1, this.searchQuery)
  }
  public async getResolve() {
    const { insurances } = await getInsuranceAgreementsResolve()
    const response = await getResolve()
    this.doctorList = response.doctors
    this.insuranceList = insurances
    // this.doctorList = doctors
  }
  @Watch('perPage')
  perPageChange() {
    this._getInsurances()
  }
  public async onPageChange(pageNumber: number) {
    await this._getInsurances(pageNumber, this.searchQuery)
  }
  get _pagination() {
    return this.pagination
  }
  public getCustomLabel(e: any) {
    return `${e.documentId} - ${e.label}`
  }
  public async _getInsurances(pageNumber = 1, searchQuery = '') {
    let doctorId: string | null = null
    if (this.userRole == Role.Staff) {
      doctorId = localStorage.getItem('selectedDoctor')
    }
    const res = await getInsuranceAgreements(
      pageNumber,
      this.perPage,
      searchQuery,
      doctorId
    )
    this.items = res.data
    this.pagination = JSON.parse(res.headers['x-pagination'])
  }
  public async create() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }
    // !this.selectedDoctor ||
    if (!this.selectedInsurance) return
    const obj = {
      ...this.form,
      expenses: +this.form.expenses,
      valueOfPremium: +this.form.valueOfPremium,
      surplusAgreement: +this.form.surplusAgreement / 100
    }
    if (obj.surplusAgreement > 1 || obj.surplusAgreement <= 0) {
      this.$bvToast.toast('The Surplus Agreement must be between 1 and 100', {
        title: 'Error!',
        variant: 'warning',
        solid: true,
        toaster: 'b-toaster-bottom-right',
        autoHideDelay: 5000,
        appendToast: true
      })
      return
    }
    if (this.userRole == Role.Admin && this.selectedDoctor?.value) {
      obj.doctorId = +this.selectedDoctor?.value
    }
    if (!this.isEditing) {
      obj.insuranceID = +this.selectedInsurance.value
      // obj.doctorId = +this.selectedDoctor.value

      await postInsuranceAgreement(obj)
    } else {
      // const obj: UpdateInsuranceAgreement = {
      //   insuranceName: this.form.insuranceName,
      //   doctorDocumentID: this.form.doctorDocumentID,
      //   valueOfPremium: this.form.valueOfPremium,
      // }
      await updateInsuranceAgreement(obj)
    }
    this.$root.$emit('bv::toggle::modal', 'modal', '#btnCreate')
    this.isEditing = false
    await this.filter()
  }
  public changeStatus(checked: boolean, item: Insurance) {
    this.$swal
      .fire({
        title: 'Are you sure?',
        text: `This insurance status will be set as ${
          checked ? 'Active' : 'Inactive'
        }`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
      })
      .then((result): void => {
        if (result.isConfirmed) {
          const obj: UpdateInsuranceStatus = {
            insuranceID: item.insuranceID,
            statusValue: checked
          }

          updateInsuranceStatus(obj).then(() => {
            this._getInsurances()
          })
          this.$swal.fire(
            `${checked ? 'Activated' : 'Deactivated'}!`,
            `The insurance has been ${
              checked ? 'Activated' : 'Deactivated'
            } successfully`,
            'success'
          )
        } else {
          item.insuranceStatus = !item.insuranceStatus
        }
      })
  }
  public formatDate(value: string) {
    return moment(value).format('MM/DD/YYYY')
  }
  public getPercentage(value: string) {
    return +value * 100 + '%'
  }
  public edit(item: InsuranceAgreementsVM) {
    this.isEditing = true
    this.form = { ...item, surplusAgreement: item.surplusAgreement * 100 }
    this.selectedInsurance = {
      value: item.insuranceID.toString(),
      label: item.insuranceName
    }
    this.selectedDoctor = {
      value: item.doctorDocumentID.toString(),
      label: item.insuranceName
    }
  }
  public addNew() {
    this.isEditing = false
    this.selectedInsurance = null // as SelectVM
    this.selectedDoctor = null //{} as SelectVM

    this.getResolve()
  }
  public hideModal() {
    this.isEditing = false
    this.form = {} as InsuranceAgreementsVM
  }
}
