
import { Component, Vue } from 'vue-property-decorator'
import {
  XlsxRead,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload
} from 'vue-xlsx'
import FileReader from 'vue-filereader'
import { uploadFile } from '@/components/patients/patients.service'
@Component({
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    FileReader
  }
})
export default class UploadView extends Vue {
  public items = []
  public file: File | null = null
  selectedSheet: string | null = null
  sheetName = null
  public onParse(e: string[]) {
    this.selectedSheet = e[0]
  }
  public myMethod(e: File) {
    this.file = e
  }
  public async upload() {
    if (!this.file) return
    const formData = new FormData()
    formData.append('file', this.file)
    const upload = await uploadFile(formData)
  }
}
