
import { Component, Vue } from 'vue-property-decorator'
import formsTable from '@/components/dashboard/forms-table.vue'
import { _getHomeForms } from '@/components/admin/forms.service'
import Multiselect from 'vue-multiselect'
import { Doctor, Role } from '@/models'
import { getDoctors } from '@/components/doctors/doctors.service'
import { getUserRole } from '@/components/admin/users/users.service'
import signalRService from '@/signalr.service'
import Terms from './Terms.vue'

@Component({ components: { formsTable, Multiselect, Terms } })
export default class DashboardView extends Vue {
  public selectedDoctor: Doctor | null = null
  public doctorList: Doctor[] = []
  public userRole = ''
  public categories = []
  public showFilter = false
  private messages = []
  public modalShow = true
  public disableAccept = true
  public handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
    if (scrollTop + clientHeight >= scrollHeight) {
      this.disableAccept = false
    }
  }
  public async created() {
    // this.$bvModal.show('terms-modal')
    // console.log('1234')
    // this.$root.$emit('bv::show::modal', 'terms-modal', '#btnCreate')
    this.userRole = getUserRole()

    if (this.userRole === Role.Superuser || this.userRole === Role.Admin) {
      this.showFilter = true
      await this._getDoctors()
    } else if (this.userRole === Role.Staff) {
      if (localStorage.getItem('selectedDoctor') != null) {
        const selectedDoctor = localStorage.getItem('selectedDoctor')
        if (selectedDoctor != undefined)
          await this.getCategories(+selectedDoctor)
      }
    } else {
      await this.getCategories()
    }
  }

  public async getCategoriesByDoctor(e: Doctor) {
    await this.getCategories(e.doctorId)
  }
  public async _getDoctors() {
    const { data } = await getDoctors(1, 1000)
    this.doctorList = data
  }
  public async getCategories(doctorId: number | null = null) {
    const { data } = await _getHomeForms(doctorId)
    this.categories = this.groupBy('formsCategoryName', data)
  }
  public getCustomLabel(a: Doctor) {
    return `${a.documentId} - ${a.fullName}`
  }
  public groupBy(key: string, array: any[]) {
    return array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key]
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
      return objectsByKeyValue
    }, {})
  }
}
