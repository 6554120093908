
import { FormVM, MagHomeForm } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormsTable extends Vue {
  @Prop() title!: string
  @Prop() data!: MagHomeForm[]
  public _data = []
  public filter = ''
  public fields = {} as {}

  public created() {
    this.data.map(c => {
      // console.log(c.formsCategoryName.trim())
      // this.fields = [{ key: 'forms' }, { key: 'record' }]

      if (c.formsCategoryName.includes('HRA')) {
        this.fields = [{ key: 'forms' }]
      } else {
        this.fields = [{ key: 'forms' }, { key: 'record' }]
      }
    })
  }
}
