
// eslint-disable-next-line @typescript-eslint/camelcase
import {
  FormVM,
  PaginationVM,
  SelectVM,
  MagForm,
  GroupFormsVM,
  DoctorSelectVM,
  CurrentUser,
  Role
} from '@/models'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Multiselect from 'vue-multiselect'
import { getResolve, postForm, _getForms, updateForm } from './forms.service'

/* eslint-disable */
// @ts-ignore
import vueShowMoreText from 'vue-show-more-text'
import { getCurrentUser, getUserRole } from './users/users.service'
import { ValidationObserver } from 'vee-validate'

@Component({
  components: {
    vueShowMoreText,
    Multiselect
  }
})
export default class FormsManagement extends Vue {
  public fields = [
    { key: 'index', label: '', sortable: true },

    { key: 'npi', label: 'NPI', sortable: true },

    { key: 'fullName', label: 'Doctor', sortable: true },
    { key: 'formsName', label: 'Form', sortable: true },
    { key: 'formsCategoryName', label: 'Form Category', sortable: true },

    { key: 'formsTypeName', label: 'Type', sortable: true },
    { key: 'url', label: 'URL', sortable: true },
    { key: 'edit', label: '' }
  ]
  public perPage = 10
  public pageOptions = [10, 20, 50, 100, 250, 500, 1000]
  public pagination = {} as PaginationVM
  // eslint-disable-next-line @typescript-eslint/camelcase
  public items = [] as MagForm[]
  public isExpanded = false

  public searchQuery = null as SelectVM | null | DoctorSelectVM
  public currentDoctor = ''
  public selectedDoctor = {} as SelectVM | undefined | string
  public selectedForm = {} as SelectVM | undefined
  public selectedFormTypeId = {} as SelectVM | undefined
  public isEditing = false
  public doctorList: DoctorSelectVM[] = []
  public formsList: GroupFormsVM[] = []
  public formsTypesList: SelectVM[] = []
  public url: string | null = null
  public formTypeDisabled = false
  public currentUser = {} as CurrentUser
  public showFilter = false
  public isAdmin = false

  @Watch('perPage')
  perPageChange() {
    this.getForms(
      this.pagination.currentPage,
      this.currentUser?.userId.toString() || this.searchQuery?.value
    )
  }
  @Watch('selectedForm')
  onSelectedForm(e: any) {
    if (this.isEditing) return
    if (e.formsCategoryName.trim() === 'HRA Services') {
      this.selectedFormTypeId = {} as SelectVM
      this.selectedFormTypeId = this.formsTypesList.find(i => i.value === '1')
      this.formTypeDisabled = true
    } else {
      // this.selectedFormTypeId = {} as SelectVM

      this.formTypeDisabled = false
    }
    // if (e.value == '13') {
    //   //HRA SIGNATURE
    //   this.selectedFormTypeId = {} as SelectVM
    //   this.selectedFormTypeId = this.formsTypesList.find(i => i.value === '1')
    //   this.formTypeDisabled = true
    // } else {
    //   // this.selectedFormTypeId = {} as SelectVM

    //   this.formTypeDisabled = false
    // }
  }
  public async cleanFilter() {
    this.searchQuery = null
    // this.currentUser = ''
    await this.getForms(1, '')
  }
  public async onPageChange(pageNumber: number) {
    await this.getForms(
      pageNumber,
      this.currentUser?.userId.toString() || this.searchQuery?.value
    )
  }
  get _pagination() {
    return this.pagination
  }
  public addNew() {
    this.isExpanded = !this.isExpanded
    if (!this.isExpanded) {
      this.selectedDoctor = {} as SelectVM
      this.selectedForm = {} as SelectVM
      this.selectedFormTypeId = {} as SelectVM
      this.url = null
      this.isEditing = false
    }
  }
  public async onSearch(e: SelectVM) {
    // this.currentUser.userId = +e.value
    this.isExpanded = false
    this.searchQuery = e
    await this.getForms(1, e.value)
  }
  public async onRemove() {
    await this.getForms(1, '')
  }

  public async created() {
    this.currentUser = getCurrentUser() //If staff, Gets the selected doctor, otherwise gets the authenticated user

    await this.getForms(1, this.currentUser?.userId.toString() || '')

    if (getUserRole() == Role.Staff) {
      this.fields = this.fields.filter(i => i.key !== 'fullName')
      this.showFilter = false
    } else if (getUserRole() == Role.Admin) {
      this.isAdmin = true
      this.showFilter = true
    } else {
      this.showFilter = true
    }
    const r = await getResolve()
    this.doctorList = r.doctors
    this.formsTypesList = r.formType
    this.formsList = r.forms.map(c => {
      return {
        formCategoryName: c.formsCategoryName.trim(),
        libs: [
          ...r.forms.filter(z => z.formsCategoryName === c.formsCategoryName)
        ]
      }
    })
    const unique = Array.from(
      new Set(this.formsList.map(s => s.formCategoryName))
    ).map(f => {
      return {
        formCategoryName: f,
        libs: this.formsList.filter(s => s.formCategoryName === f)[0].libs
      }
    })
    // console.log(unique)
    this.formsList = unique
    this.getForms()
  }

  public async create() {
    const isValid = await this.$refs.observer.validate()
    if (!isValid) {
      return
    }

    if (
      // this.selectedDoctor?.value &&
      this.selectedForm?.value &&
      this.selectedFormTypeId?.value
    ) {
      let doctorId = 0
      if (this.searchQuery?.value) {
        doctorId = +this.searchQuery.value
      } else {
        doctorId = this.currentUser.userId
      }

      const obj: FormVM = {
        doctorID: doctorId,
        formsID: +this.selectedForm.value,
        formsTypeID: +this.selectedFormTypeId.value,
        url: this.url
      }

      this.isEditing ? await updateForm(obj) : await postForm(obj)

      this.selectedDoctor = {} as SelectVM
      this.selectedForm = {} as SelectVM
      this.selectedFormTypeId = {} as SelectVM
      this.url = null
      this.isExpanded = false
      this.isEditing = false
    }
    this.getForms(this.pagination.currentPage, this.searchQuery?.value)
  }
  public getCustomLabel(e: any) {
    return `${e.documentId} - ${e.label}`
  }
  public async getForms(pageNumber = 1, searchQuery = '') {
    const response = await _getForms(pageNumber, this.perPage, searchQuery)
    this.items = response.data

    this.pagination = JSON.parse(response.headers['x-pagination'])
  }
  public edit(item: MagForm) {
    this.isExpanded = true
    this.isEditing = true
    this.url = item.url
    // this.selectedDoctor =

    this.searchQuery =
      this.doctorList.find(i => i.value === item.doctorId.toString()) || null
    // this.searchQuery.value = s?.documentId.toString() || ''
    // this.searchQuery.label = item.fullName

    this.selectedForm = {
      value: item.formsID.toString(),
      label: item.formsName
    }
    // this.selectedForm = this.formsList.find(
    //   i => i.value === item.formsID.toString()
    // )
    this.selectedFormTypeId = this.formsTypesList.find(
      i => i.value === item.formsTypeID.toString()
    )
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // console.log(item)
  }
}
